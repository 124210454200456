@import '../src/styles/themes/theme-dark.scss';
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans&display=swap");

html {
	font-size: 62.5%;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
}

.aligner {
	display: flex;
	align-items: center;
	justify-content: center;
}

  
* {
	font-family: "Montserrat";
}

// For All Components

.section-title {
	padding-bottom: 4%;
	padding-top: 5%;
	font-size: 350%;
	text-align: center;
	font-weight: 600;
	font-family: 'Montserrat';
	margin: 0 auto 18px auto;
	width: 100%;
}


// For Navbar.js

@font-face {
	font-family: 'GoldenSignature';
	font-style: normal;
	font-weight: normal;
	src: local('GoldenSignature'), url('./styles/GoldenSignature.otf') format('woff');
	font-display: swap;
  }

.logo-name {
	font-family: "GoldenSignature";
	font-size: 40px;
	font-weight: normal;
	font-variant-ligatures: no-common-ligatures;
	-webkit-font-variant-ligatures: no-common-ligatures;
	padding: 0 8px;
	color:  white;
	padding-top: 100px;
}

.logo-name-brackets {
	font-family: "GoldenSignature";
	font-size: 20px;
	font-weight: 700;
	font-variant-ligatures: no-common-ligatures;
	-webkit-font-variant-ligatures: no-common-ligatures;
	color:  white;
}

.button {
	background-color: transparent;
	border: none;
	opacity: 0.7;
	transition: all 0.2s ease-in-out;
	&:focus {
		outline: none;
	}
	&:hover {
		opacity: 1;
	}
}

.navbar-text {
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: 600;
	font-variant-ligatures: no-common-ligatures;
	-webkit-font-variant-ligatures: no-common-ligatures;
	color:  white;
}


// For Header.js

header {
	position: relative;
	height: 520px;
	min-height: 450px;
	width: 100%;
	background-size: cover;
	-webkit-background-size: cover;
	text-align: center;
	overflow: hidden;
	background-color: #e9d5a1;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding-top: 30px;
	padding-bottom: 0;
}

header h1 {
	font-size: 400%;
	text-align: center;
	font-weight: 600;
	font-family: 'Montserrat';
	margin: 0 auto 18px auto;
	width: 100%;
}

.title-container {
	height: 50px;
}

.title-styles {
	font-family: 'Montserrat';
	font-size: 240%;
}

.header-icon {
	height: 150px;
	width: 150px;
}

header a {
	color: white;
}

header a:hover {
	#mail {
		color: #ECDBBA;
	}
	#github {
		color: #333333;
	}
	#linkedin {
		color: #0A66C2;
	}
	#instagram {
		color: #C13584;
	}
}

.social-links-header {
	margin-top: 40px;
	font-size: 35px;
}


// For About.js

#about {
	overflow: hidden;
}

.polaroid img {
	display: block;
}

.polaroid span {
	background: #ffffff;
	display: inline-block;
	margin: 25px 75px 30px;
	padding: 30px 25px 30px;
	text-align: center;
	text-decoration: none;
	-webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-moz-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-webkit-transition: all .20s linear;
	-moz-transition: all .20s linear;
	transition: all .20s linear;
	z-index: 0;
	position: relative;
}

.hello-world {
	font-size: 175%;
	text-align: center;
	padding-top: 5%;
	font-weight: 600;
	font-family: 'Montserrat';
	margin: 0 auto 18px auto;
	width: 100%;
}

.about-me-paragraph-font {
	text-align: center;
	padding-top: 5%;
	font-weight: 400;
	font-family: 'Montserrat';
	margin: 0 auto 18px auto;
}


// For Education.js

#education {
	background: #1F1F1F;
	min-height: 200px;
	width: 100%;
	overflow: hidden;
	padding-bottom: 8%;
}

.education-tile {
	background-color: transparentize(#F8F5F4, 0.95);
	margin-top: 0rem;
	padding-top: 3rem;
	padding-bottom: 3rem;
	margin: 1rem 10px 5px 0px;
	width: 310px;
	height: 400px;
	border-radius: 30px;
}

.school-name {
	font-family: 'Montserrat';
	font-weight: 501;
	font-size: 165%;
	margin-top: 20px;
}

.education-degree-text {
	margin-top: 3rem;
	font-style: italic;
}

.education-years {
	margin-top: 3rem;
}


// For Experience.js

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.company-logo {
	margin-bottom: 1rem;
}

.experience-icon {
	font-size: 300%;
	margin-top: 25%;
	text-align: center;
}


// For Projects.js and ProjectDetailsModal.js

.project-date {
	font-size: 16px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	padding: 8px 15px 2px 15px;
	position: relative;
	z-index: -500;
	border-top: 5px solid #696969;
	border-radius: 0 0 5px 5px;
	background-color: #696969;
	color: white;
}

.slider-image {
	border: 5px solid #D7CAAA;
}

.slider-tab {
	background-color: #D7CAAA;
	height: 25px;
}

.slider-iconfiy {
	margin-top: 10px;
}

.styles_typicalWrapper__1_Uvh::after {
	cursor: none !important;
	display: none;
}

.foto img {
	display: block;
	width: 100%;
}

.foto div {
	background: #ffffff;
	display: inline-block;
	margin: 0 auto 5% auto;
	padding: 10px 10px 5px;
	text-align: center;
	text-decoration: none;
	-webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-moz-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-webkit-transition: all .20s linear;
	-moz-transition: all .20s linear;
	transition: all .20s linear;
	z-index: 0;
	position: relative;
}

.foto div:after {
	color: #333;
	font-size: 25px;
	content: attr(title);
	position: relative;
	top: 15px;
}

.foto div:hover {
	-webkit-transform: scale(1.01);
	-moz-transform: scale(1.01);
	transform: scale(1.01);
	z-index: 10;
	-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
	-moz-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
	box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
}

.project-title-settings {
	margin-top: 5%;
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: black;
	text-align: center;
}

.link-href {
	color: black;
}

@media only screen and (max-width: 1170px) {
	.experience-icon {
		font-size: 170%;
		margin-top: 27%;
		text-align: center;
	}
}

.modal-inside .modal-content {
	background: white;
}

.bars {
	width: 95%;
	float: left;
	padding: 0;
	text-align: left;
}

.bars .skills {
	margin-top: 36px;
	list-style: none;
}

.bars li {
	position: relative;
	margin-bottom: 60px;
	background: #ccc;
	height: 42px;
	border-radius: 3px;
}

.bars li em {
	font: 15px 'opensans-bold', sans-serif;
	color: #313131;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: normal;
	position: relative;
	top: -36px;
}

.bar-expand {
	position: absolute;
	left: 0;
	top: 0;
	margin: 0;
	padding-right: 24px;
	background: #313131;
	display: inline-block;
	height: 42px;
	line-height: 42px;
	border-radius: 3px 0 0 3px;
}

.modal-close {
	text-align: right;
	padding: 10px 15px 10px 15px;
	cursor: pointer;
}

.close-icon {
	color: black;
	font-weight: lighter !important;
}

.modal-description {
	text-align: justify;
	padding: 5px 5px 0 5px;
	margin-bottom: 20px;
	font-size: 12px;
}

.awssld__next {
	outline: none !important;
}

.awssld__prev {
	outline: none !important;
}

.loader-bar-color {
	color: black !important;
}

#projects {
	background: #efe1bd;
	padding-bottom: 5%;
}

.projects-item {
	max-width: 100%;
	margin-bottom: 15px;
	text-align: center;
}

.projects .projects-item .projects-item-caption {
	-webkit-transition: all ease 0.5s;
	-moz-transition: all ease 0.5s;
	transition: all ease 0.5s;
	opacity: 0;
	background-color: rgba(51, 51, 51, 0.9);
}

.projects .projects-item .projects-item-caption:hover {
	opacity: 1;
}

.projects .projects-item .projects-item-caption .projects-item-caption-content {
	font-size: 1.5rem;
}

@media (min-width: 576px) {
	.projects .closeButtonResponsive {
		display: block;
	}
	.projects .projects-item {
		margin-bottom: 30px;
	}
}


// For Skills.js

#skills {
	background: #1F1F1F;
	min-height: 200px;
	width: 100%;
	overflow: hidden;
	padding-bottom: 8%;
}

.skills-tile {
	background-color: transparentize(#F8F5F4, 0.95);
	padding: 15px 10px 5px 10px;
	width: 100px;
	margin: 5px 0 5px 0;
	border-radius: 8px;
}

.skills-name {
	color: white;
}

.skill-icon {
	color: white;
	text-align: center;
	position: relative;
}


// For Footer.js

footer {
	background: #494949;
	min-height: 100px;
	width: 100%;
	overflow: hidden;
	font-size: 14px;
	color: white;
	position: relative;
	text-align: center;
}

footer a {
	color: white;
}

footer a:hover {
	#mail {
		color: #ECDBBA;
	}
	#github {
		color: #333333;
	}
	#linkedin {
		color: #0A66C2;
	}
	#instagram {
		color: #C13584;
	}
}

.social-links-footer {
	margin-top: 50px;
	font-size: 28px;
}